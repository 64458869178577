.ethConvertInput {
    width: 100%;
    background-color: #232320;
    border: 1px solid #5c5a5a;
    height: 33px;
    color: white;
    padding-left: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
}

.ethb {
    width: 50%;
    text-align: left;
    font-size: 12px;
    float: left;
    color: grey;
}

.wethb {
    width: 50%;
    text-align: right;
    font-size: 12px;
    color: grey;
    float: right;
}

.infin-switch {
    margin-top: 17px;
    float: left;
    margin-right: 17px;
}
.infinite-approval {
    width: 80%;
    float: left;
    font-weight: 400;
    margin-top: 7px;
}

.ethConvertMaxButton {
    color: #07d07a;
    position: absolute;
    right: 48px;
    top: 218px;
    font-size: 13px;
    background: none;
    border: none;
}