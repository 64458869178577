.myOffers {
    background-color: #191918;
    min-height: 100vh;
    padding-bottom: 100px;
    color: white;
    background: linear-gradient(to bottom, #1c2023, #070708);
    
    .offer {
        margin-bottom: 10px;
    }
}

.offerWarning {
    padding-top: 50px;
    text-align: center;
}