.pricing-helper {
    font-size: 13px;
    width: 100%;
    margin-top: 13px;
    span {
        font-size: 13px!important;
    }

}
.swap-holder{
    // height: 350px;
    width: 100%;
    background-color: #222220;
    padding: 15px;
    display: inline-flex;
    border: 1px solid #444;
    flex-wrap: wrap;
    border-radius: 10px;
    .lab {
        margin-bottom: 5px; 
        color: white;
        margin-top: 5px;
        &.margin {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .new-feature {
        color: #15f778;
        font-size: 13px;
    }

    .whitelist-input {
        width: 100%;
        margin-bottom: 5px;
        input {
            background-color: #2f2f2f;
            border: 1px solid grey;
            color: white;
            height: 35px;
            width: 100%;
            border-radius: 5px;
            outline: none;
            padding-left: 7px;
        }
    }

    .whitelist-holder {
        margin-top: 10px;
        width: 100%;
    }

    .note-holder {
        width: 100%;
    }

    .note-text {
        margin-top: 9px;
        font-size: 13px;
        font-weight: 300;
        width: 90%;
        float: left;
        @media(max-width: 767px) {
            width: 45%;
        }
    }

    .whitelist-text {
        margin-top: 9px;
        font-size: 13px;
        font-weight: 300;
        width: 60%;
        float: left;
        @media(max-width: 767px) {
            width: 45%;
        }
        
    }

    .note-area {
        width: 100%;
        p {
            font-size: 13px;
            font-weight: 300;
            background-color: grey;
            padding: 5px;
            border: 1px solid white;
        }
        textarea {
            width: 100%;
            resize: none;
            background-color: #2f2f2f;
            border: none;
            border-radius: 6px;
            outline: none;
            color: white;
            padding: 10px;
            height: 100px;
        }
    }

    small.negative-text p {
        color: rgb(5, 224, 143);
        padding-right: 40px;
        width: 70%;
        font-size: 13px!important;
    }

    .deadline {
        width: 20%;
        float: right;
        font-weight: 300;
        margin-top: 3px;
        margin-bottom: 0;
        font-size: 12px;
        
        @media(max-width: 1400px) {
            width: 25%;
        }

        @media(max-width: 1200px) {
            width: 30%;
        }


        @media(max-width: 990px) {
            width: 40%;
            float: left; 
            i {
                margin-right: 0px;
                margin-left: 8px!important;
            }
        }


        @media(max-width: 768px) {
            width: 43%;
            min-width: 128px;
            font-size: 12px;
            margin-top: 8px;
            float: right;
            i {
                margin-right: 5px!important;
                margin-left: 14px!important;
            }
            input {
                width: 28%!important;
                margin-right: -2px!important;
                padding-top: 5px!important;
            }
            select {
                width: 45%!important;
            }
        }
        


        i {
            color: #cccccc;
            margin-top: 8px;
            margin-left: 9px;
        }

        
        select {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            width: 49%;
            background-color: #2f2f2f;
            border: none;
            color: white;
            height: 30px;
            float: right;
            outline: none;
            font-weight: 300;
            border-right: 5px solid #2f2f2f;
        }

        input {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            width: 24%;
            background-color: #2f2f2f;
            border: none;
            color: white;
            height: 30px; 
            font-weight: 300;
            float: right;      
            outline: none;
            padding-left: 10px;

            
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    }
    .switch {
        float: left;
        margin-top: 10px;
        margin-right: 10px;
    }
}

.warn {
    p {
        text-align: center;
        color: #cb6060;
        font-size: 12px;
        width: 100%;
        margin-top: 50px; 
        margin-bottom: 15px;
    }

    a.warn-link {
        font-weight: 200!important;
        padding: 5px 10px;
        font-weight: 600; 
        width: 100%;
        text-align: center;
        background-color: #f3dde0;
        font-size: 14px;
        color: #cb6060;
    }
}

.search-input {
    padding-left: 10px;
    width: 90%;
    color: white;
    background-color: #201f1f;
    margin-left: 5%;
    border: none;
    margin-bottom: 15px;
    height: 40px;
    outline: none;
}

.fees-info {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 300;
    color: white;
    margin-bottom: 0;
}

.modal-picker {
    padding-top: 30px;
    left: calc(50% - 250px);
    top: 150px;
    width: 500px; 
    height: 500px; 
    background-color: #151514;
    z-index: 3;
    position: fixed;
    overflow-y: scroll;
    @media(max-width: 880px) {
        left: 0;
        width: 100%;
        font-size: 12px;
        img {
            // display: none;
            height: 20px!important;
            width: 20px!important;
            margin-top: 6px;
        }
        
        .name {
            width: unset!important;
        }
    }
    .item {
        padding-top: 10px;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        &:hover {
            background-color: #201f1f;
            cursor: pointer;
        }
        img {
            height: 30px;
            width: 30px;
            float: left;
            margin-right: 10px;
        }
    
        .symbol {
            color: grey;
        }
        .name {
            margin-top: 5px;
            width: 300px;
            float: left;
        
        }
        .selector {
            color: #777;
            float: right;
            margin-right: 20px;
            position: relative;
            top: 5px;
        }

    }

}

.wrapper-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000073;
    z-index: 2;
}