.airdrop {
    margin-bottom: 50px;
}

.wrong-network {
    color: white;
    background-color: #ff0758;
    margin-bottom: 10px;
    text-align: center;
    padding: 20px;
}

.legal-box {
    input {
        margin-right: 5px;
    }
    p {
        margin: 0!important;
    }
    border: 1px solid white;
    background-color: grey;
    padding: 10px;
}

.note-inform {
    margin-top: 15px;
    color: white;
    text-align: center;
}
.note-locked {
    width: 100%;
    background-color: #333;
    position: relative;
    border-radius: 6px;
    .reveal-btn {
        position: absolute;
        top: 25px;
        height: 35px;
        left: calc(50% - 200px);
        width: 400px;
        border-radius: 6px;
        border: none;
        outline: none;
        @media(max-width: 880px) {
            top: 30%;
            width: 90%;
            height: 50px;
            left: 5%;
        }
    }
    p.hidden {
        padding: 5px;
        color: #887e7e;
        text-align: center;
        padding-top: 19px;
        filter: blur(4px);
    }
    p.revealed {
        padding: 10px;
        text-align: center;
        color: #887e7e;
        min-height: 50px;
    }
}

.takerHolder {
    padding-top: 30px;
    background-color: #191918;
    min-height: 100vh;
    background: linear-gradient(to bottom, #1c2023, #070708);




    .timeLeft {
        background-color: #222220;
        color: white;
        text-align: center;
        padding: 10px;
        margin-bottom: 0;
        border-top: 1px solid #2f2f2f;
        &.expired {
            color: white;
            background-color: #d22b2a;
        }
        @media(max-width: 880px) {
            padding: 10px;
        }
    }
}

.unavailable {
    margin-left: calc(50% - 250px);
    text-align: center;
    color: white;
    width: 500px;
}


.whitelist-neutral {
    background-color: #222220;
    color: white;
    padding: 15px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
}
.whitelist-warning {
    background-color: #dc3545; 
    color: white;
    padding: 15px;
    font-weight: 300;
    text-align: center;
}

.whitelist-confirm {
    background-color: #2f2f2f; 
    color: white;
    padding: 15px;
    font-weight: 300;
    text-align: center;
}

.expired-offer {
    h2 {
        color: white;
    }
}

.fees {
    font-size: 13px;
    color: grey;
}

.terms-holder {
    margin-top: 20px;
    background-color: #222220;
    color: white;
    padding: 15px;
    padding-top: 5px;
}

.sec { 
    margin-top: -30px!important;
}

.small-balance {
    text-align: right;
    color: grey;
    font-size: 12px;
    bottom: 49px;
    right: 30px;
    z-index: 2;
    position: relative;
}

.small-address {
    text-align: left;
    color: #ff5700;
    margin-top: 10px;
    font-size: 12px;
}

.offerTakerHolder {
    background-color: #222220;
    padding: 15px;
    color: white;
    padding-top: 1px;
    margin-top: 20px;
    p {
        margin-top: 20px;
    }
}


