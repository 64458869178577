.social-share { 
    @media(max-width: 880px) {
        width: 100%;
        button {
            width: 100%!important;
            margin-bottom: 10px;
        }
    }

    h2 {
        // font-family: 'Newsreader', serif!important;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 10px;
    }
    input {
        width: 90%;
        height: 33px;
        background-color: #313131;
        border: 1px solid #5a5555;
        float: left;
        padding-left: 10px;
        color: white;
        &.copied {
            background-color: #95f4c3;
            border: 2px solid green;
            color: black;
        }
    }
    div.copy {
        background-color: #1d1d1b;
        border: 1px solid #5a5555;
        height: 33px;
        padding-top: 4px;
        width: 9%;
        margin-left: 1%;
        float: left;
        text-align: center;
        cursor: pointer;
    }
    div.visit {
        margin-left: 1%;
        background-color: #1d1d1b;
        border: 1px solid #5a5555;
        height: 33px;
        padding-top: 4px;
        width: 9%;
        float: left;
        text-align: center;
        cursor: pointer;
    }
    .btn-holder {
        margin-top: 20px;
    }
    .telegram {
        margin-right: 10px;
    }
}