.input-holder {
    background-color: #2f2f2f;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 100px;
    position: relative;

    ::placeholder {
        color: rgb(119, 128, 160)
    }

    .currency {
        cursor: pointer;
        width: 150px;
        height: 35px;
        background-color: #222220;
        border-radius: 50px;
        position: absolute;
        top: 15px;
        right: 20px;

        &.noselect {
            @media(max-width: 880px) {
                width: 120px!important;
            }
        }

        .selector-currency {
            position: relative;
            img.image-symbol {
                width: 28px;
                height: 28px;
                margin-left: 5px;
                position: absolute;
                left: 0;
                top: 3px;
                border-radius: 50%;
            }
    
            .title-currency {
                position: absolute;
                font-size: 20px;
                border: none;
                left: 45px;
                top: 2px;
                background: none;
                outline: none;

                i {
                    position: absolute;
                    top: 7px;
                    left: 80px;
                }
            }
        }

    }
    .balance {
        font-weight: 200;
        bottom: 0px;
        right: 20px;
        font-weight: 300;
        font-size: 13px;
        position: absolute;
        color: rgb(119, 128, 160);

        &.clickable {
            padding: 4px 6px;
            color: rgb(5, 224, 143);
            font-weight: 600;

        }
    }

    input {
        position: absolute;
        left: 20px;
        top: 10px;
        color: white;
        background-color: #2f2f2f;
        width: 50%;
        outline: none;
        font-size: 30px;
        border: none;
        @media(max-width: 880px) {
            font-size: 25px!important;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
}