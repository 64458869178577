.footer {
    background-color: #1f1f1f;
    width: 100%;
    padding: 30px 110px;
    @media(max-width: 880px) {
        padding: 20px;
        padding-top: 70px;
        padding-bottom: 50px;
    }

    .container {
        h3 {
            color: grey;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 10px;
        }
        i {
            margin-right: 10px;
        }
        p {
            color: white; 
            font-size: 14px;
            font-weight: 200;
        }
        a {
            text-decoration: none;
            cursor: pointer;
            font-weight: 200;
            color: white!important;
            width: 100%;
            font-size: 14px;
            float: left;
            margin-bottom: 7px;
        }
    }
    .col-md-2 {
        float: left;
    }
    .col-md-4 {
        float: left;
    }
}