.page {
    h5 {
        margin-bottom: 10px;
    }
    .forming {
        @media(max-width: 880px) {
            width: 90%;
        }
        background-color: #222220;
        padding: 40px;
        width: 50%;
        border-radius: 10px;
        border: 1px solid #444
    }
    padding-bottom: 50px;
    text-align: center;
    .warn {color: orange;}
    min-height: calc(100vh - 200px);
    background-color: #1c2023;
    font-weight: 300;
    color: white;
    padding-top: 50px;
    @media(max-width: 880px) {
        padding-top: 20px;
    }
    .title {
        color: white;
        font-size: 17px;
        span {
            font-size: 17px;
        }
    }
    .inputHolder {
    
        margin-top: 20px;
        color: white;

        label {
            color: white;
        }
        
        input {
            @media(max-width: 880px) {
                max-width: 100%;
            }
            width: 400px;
            text-align: center;
            background-color: #2f2f2f;
            border: none;
            margin-top: 5px;
            border-radius: 10px;
            color: white;
            height: 40px;
            padding-left: 20px;
        }
        p {
            margin-top: 5px;
            small {
                font-weight: 300;
                color: grey;
            }
        }
    }
}