.myOffer {
    background-color: #1d1d1d;
    padding: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    position: relative;
    font-weight: 200;
    cursor: pointer;

    .status {
        margin-bottom: 0;
        &.whitelist {
            font-weight: 400;
            margin-top: -10px;
            color: #15f778;
        }
    }

    p {
        font-weight: 200;
    }

    .offerP {
        margin-top: 5px;
        font-weight: 400!important;
        font-size: 17px;

        span {
            font-size: 17px;
            font-weight: 400!important;
        }
    }

    .createdAt {
        color: #726767!important;
    }


    small {
        font-weight: 300;
    }
    span {
        color: #15f778;
    }

    i {
        position: absolute;
        top: 20px;
        right: 20px;
    }
}