.works {
    .number-step {
        background-color: #33383f;
        color: white;
        width: 60px; 
        height: 25px;
        padding-top: 3px;
        margin-top: 8px;
        font-size: 12px;
        text-align: center;
        border-radius: 20px;
        float: left;
        margin-right: 20px;
        &.check {
            background-color: #32b322;
            width: 25px;
            color: white;
        }
    }
    p {
        margin-bottom: 0;
    }
    p.subtxt {
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: 300;
        color: white;

        &.info {
            color: grey;
        }
    }
    .text-holder {
        margin-top: 4px;
       margin-left: 80px;

       &.check {
        margin-left: 50px;
       }
    }
    background-color: #191918;
    border: 1px solid #222220;

    &.info {
        border: none;
        background-color: #222220;
        background-image: none;
    }
    // background-color: #FA8BFF;
    // background-image: linear-gradient(45deg, #c660b9 0%, #22a7d4 52%, #1ecf62 90%);
    background-color: #BE93C5;
    background-image: linear-gradient(147deg, #BE93C5 0%, #7B73A4 74%);


    width: 100%;
    margin-bottom: 20px;
    padding: 10px 20px;
    padding-top: 10px;
    border-radius: 10px;
}
.block {
    min-height: 100vh;
    width: 100%;
    z-index: 5;
    position: fixed;
    height: 100vh;
    top: 0;
    background-color: black;
    text-align: center;
    color: white;
    padding: 100px;
    @media(max-width: 880px) {
        padding: 20px;
        padding-top: 100px;
    }
}

.sub-menu-title {
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    background-color: #222220;
    color: white;
    margin-right: 10px;
    text-decoration: none;
    margin-bottom: 10px;

    &.active {
        background-color: white;
        color: black;
    }
}

.swap-page {

    background-color: #191918;

    background: linear-gradient(to bottom, #1c2023, #070708);
    min-height: 100vh;
    padding-top: 10px;
    margin-top: -2px;
    color: white;
    h3 {
        // font-family: 'Newsreader', serif;
        // text-decoration: underline;
        // text-decoration-color: #15f778;

        font-size: 46px;
        font-weight: 300;
        text-align: center;
    }
    h5 {
        font-size: 18px;
        font-weight: 200;
        text-align: center;
        margin-bottom: 20px;
        margin-bottom: 10px;
    }
}

.modal-confirm {
    left: calc(50% - 175px);
    top: 150px;
    width: 350px; 
    color: white;
    padding: 40px;
    background-color: #1e1f1f;
    z-index: 3;
    position: fixed;
    
}

.modal-share {
    left: calc(50% - 250px);
    top: 250px;
    width: 500px; 
    padding: 25px;
    background-color: #1d1d1b;
    z-index: 3;
    position: fixed;
    overflow: scroll;
    @media(max-width: 880px) {
        width: 100%;
        left: 0;
        padding: 20px;
        height: unset;
        button {
            width: 100%!important;
            margin-bottom: 10px;
        }
    }
}

.center-spin {
    width: 40px;
    height: 40px;
    margin-lefT: calc(50% - 20px);
}

.terms { 
    margin-top: 10px; 
    margin-bottom: 10px;
    font-weight: 200;
    font-size: 12px;
}


.btn-send {
    background-color: black;
    color: white;
    margin-bottom: 10px;
}

// .flex-gap > div {
//     margin: 6px;
//   }

i.close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}