* {
    font-size: 15px;
    font-weight: 600;
    --account-center-position-bottom: 20px;
}

.banner-warn {
  padding: 10px 20px;
  text-align: center;
}

:root {
  --background-color: #0d0c0c; 
  --text-color: #fafafa; 
  --border-color: #ebebed; 
  --accent-background: #171717; 
  --accent-color: #1df753; 
  --accent-color-hover: #292929; 
  --secondary-text-color: #707481; 
  --onboard-connect-sidebar-background: var(--accent-background);
  --onboard-close-button-background: var(--accent-background);
  --onboard-connect-sidebar-color: var(--text-color);
  --onboard-connect-sidebar-progress-background: var(--secondary-text-color);
  --onboard-connect-sidebar-progress-color: var(--accent-color);
  --onboard-connect-header-background: var(--background-color);
  --onboard-connect-header-color: var(--text-color);
  --onboard-main-scroll-container-background: var(--background-color);
  --onboard-link-color: var(--accent-color);
  --onboard-wallet-button-color: #ebebed; 
  --onboard-wallet-button-color-hover: #ebebed; 
  --onboard-wallet-button-background: var(--background-color);
  --onboard-wallet-button-background-hover: var(--accent-color-hover);
  --onboard-wallet-button-border-color: var(--border-color);
  --onboard-wallet-app-icon-border-color: var(--border-color);
}

.warning-message {
    background-color: #562c39;
    color: white;
    padding: 10px 20px;
    text-align: center;
    i {
        margin-right: 10px;
    }
}

.btn-confirm {
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
    font-weight: 500;
    &:hover, &:active {
        background-color: #444!important;
        color: white!important;
    }
    &.mid {
        margin-top: 10px;
        width: 100%;
    }
    &.dis {
        background-color: grey;
        border: none;
    }
    &.twitter-follow {
      width: 100%;
      margin-top: 10px;
      background-color: #0d81ff;
      color: white;
      i {
        margin-right: 10px;
      }
    }

    &.meme {
      width: 33%;
      background-color: #3d3d38;
      color: white;
    }

    &.twitter {
      width: 49%;
      margin-right: 1%;
      color: white;
      background-color: #0d81ff;
      color: white;
    }


    &.orders {
      width: 49%;
      margin-right: 1%;
    }

    &.telegram {
      width: 48%;
      border: 1px solid white;
      color: white;
      background-color: #2587cb;;
    }
}

.showAddress {
  border: none;
  padding-left: 0;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.hidden-mobile {
    padding-top: 18px;
    display: block!important;
    @media(max-width: 766px) {
        display: none!important;
    }
}

.hidden-desktop {
    display: none!important;
    @media(max-width: 766px) {
        display: block!important;
    }
}


.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border: 4px solid #555;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #555 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #15f778;
}

input:focus + .slider {
  box-shadow: 0 0 1px #15f778;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
  