.hidden-mobile {
    padding-top: 10px!important;
}

.dropd-network {
    cursor: pointer;
    small {
        color: grey;
        width: 200px;
    }
    p {
        margin-top: 4px;
        margin-left: 0;
        img {
            margin-top: -2px;
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }

    height: 40px;
    border-top: 1px #E7E7E7;
    background-color: #2f353e;
    color: white;
    position: relative;
    top: 7px;
    width: 165px;
    padding: 5px 15px;
    padding-top: 5px;
    padding-left: 15px;
    left: -15px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;    
}
.custom-network-select {
    &.mobile {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    cursor: pointer;
    background-color: #404752;
    color: white;
    float: left;
    padding: none;
    padding: 5px 15px;
    padding-top: 5px;
    margin-right: 22px;
    height: 35px;
    border-radius: 5px;
    width: 165px;
    margin-top: 0;
    position: relative;

    &.open {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    img {
        margin-top: -2px;
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-right: 8px;
        
    }
    .fa-chevron-down {
        position: absolute;
        right: 10px;
        top: 8px;
        color: lightgrey;
    }
}
  
  
.navbar-dark {
    background-color: #1c2023!important;
    padding: 20px;
    padding-top: 5px;

    @media(max-width: 880px) {
        padding-top: 20px;
    }

    .navbar-brand {
        color: white!important;
    } 

    .fakeNav {
        padding-top: 9px;
        padding-right: 0px;
        padding-left: 15px;
        a.active {
            font-weight: 500!important;
        }
        @media(max-width: 880px) {
            padding-left: 0;
            padding-top: 20px;

        }
    }

    a{ 
        color: white!important;
        font-weight: 200;

        &.active {
            color: white!important;
            font-weight: 400;
        }
        &.nonactive {
            font-weight: 200!important;
        }
    }
}

.profile {
    float: left;
    color: white;
    margin-right: 10px;
    margin-top: 0px;
    width: 160px;

    .circ {
        margin-left: 5px;
        float: right;
        border: 1px solid #7260c1;
        background-color: white;
        color: #7260c1;
        font-weight: 600;
        border-radius: 50%;
        width: 24px;
        font-size: 12px;
        padding-top: 2px;
        height: 24px;
        text-align: center;
    }
    p {
        border: 1px solid #7260c1;
        padding: 5px 10px;
        background-color: #7260c1;
        border-radius: 5px;

    }
}

a.nav-link {
    padding-top: 9px;
    padding: 5px 10px;
    &.holo {
        border-radius: 20px;
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 10px;
        background-color: #FF9A8B;
        background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);
        color: white!important;
        font-weight: 400;
    }
}

.navbar-toggler {
    border: none;
}

.add-margin-top {
    margin-top: 7px;
}

.conn {
    margin-top: 0;
    height: 38px;
}

